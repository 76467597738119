"use strict";
/*!
 * © 2020 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandManagerClient = void 0;
const uuid_1 = require("uuid");
const command_manager_1 = require("./command-manager");
class CommandManagerClient {
    constructor(messagingPort, logger, clientConnectionEndpoint) {
        this.logger = logger;
        this.clientConnectionEndpoint = clientConnectionEndpoint;
        this.commandsMap = new Map();
        this.clientConnectionEndpoint.connect(messagingPort);
        this.clientConnectionEndpoint.setRpcServicesImplementation('command-manager-client', {
            invoke: (invokeArgs) => {
                const { command, id, context } = invokeArgs;
                if (!command || !id) {
                    return Promise.reject({ message: 'No command or id provided' });
                }
                return this.invokeHandler(command, id, context);
            },
        });
    }
    register(command, handler, priority = command_manager_1.HandlerPriority.Medium) {
        return __awaiter(this, void 0, void 0, function* () {
            const handlerInfo = { handler, id: uuid_1.v4(), priority };
            this.logger.info(`CommandManagerClient: Register handler to ${handlerInfo.id} with prioirty ${handlerInfo.priority}`);
            if (this.commandsMap.has(command)) {
                const handlers = this.commandsMap.get(command) || [];
                handlers.push(handlerInfo);
                this.commandsMap.set(command, handlers);
            }
            else {
                this.commandsMap.set(command, [handlerInfo]);
            }
            try {
                yield this.clientConnectionEndpoint.performRpcCall('command-manager-host', 'register', [
                    { command, id: handlerInfo.id, priority: handlerInfo.priority },
                ]);
            }
            catch (e) {
                this.logger.error(new Error(`CommandManagerClient: Register error: ${e}`));
                Promise.reject(e);
            }
            this.logger.info(`CommandManagerClient: Handler registered successfully`);
            return Promise.resolve({ command, id: handlerInfo.id });
        });
    }
    unregister(info) {
        this.logger.info(`CommandManagerClient: Unregistering handler ${info.id} from command: ${info.command}`);
        const handlerIds = this.commandsMap.get(info.command);
        if (!handlerIds) {
            return;
        }
        const index = handlerIds.findIndex((entry) => entry.id === info.id);
        if (index > -1) {
            handlerIds.splice(index, 1);
        }
        this.logger.info(`CommandManagerClient: Handler ${info.id} was unregistered from command: ${info.command}`);
    }
    invokeHandler(command, id, context) {
        const handler = this.getHandlerByComandId(command, id);
        if (!handler) {
            this.logger.error(new Error(`CommandManagerClient: Handler not found`));
            return Promise.reject({ message: 'Handler not found' });
        }
        return handler(context);
    }
    getHandlerByComandId(command, id) {
        var _a, _b;
        return (_b = (_a = this.commandsMap.get(command)) === null || _a === void 0 ? void 0 : _a.find((h) => h.id === id)) === null || _b === void 0 ? void 0 : _b.handler;
    }
}
exports.CommandManagerClient = CommandManagerClient;
