"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EndPointConfig = void 0;
class EndPointConfig {
}
exports.EndPointConfig = EndPointConfig;
EndPointConfig.LoggerEndPoint = '/federatedui/pagecomposer/writelog';
