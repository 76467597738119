"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var EventHost = /** @class */ (function () {
    function EventHost(eventChannelFactory) {
        this.eventChannelFactory = eventChannelFactory;
        this.channels = new Map();
    }
    EventHost.prototype.createEventEmitter = function (channelDef) {
        var channelName = channelDef.name;
        var channel = this.getOrCreateChannel(channelName);
        if (channel.senderConnected) {
            throw new Error("Event emmiter with channel name " + channelName + " is already used");
        }
        var senderEndpoint = channel.connectSender();
        return {
            emit: function (eventName) {
                var _a = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    _a[_i - 1] = arguments[_i];
                }
                var arg = _a[0];
                if (senderEndpoint.getState() === 'disconnected') {
                    throw new Error('Emitter is disconnected');
                }
                return senderEndpoint.emitEvent(channelName, eventName, arg);
            },
            syncEmit: function (eventName) {
                var _a = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    _a[_i - 1] = arguments[_i];
                }
                var arg = _a[0];
                if (senderEndpoint.getState() === 'disconnected') {
                    throw new Error('Emitter is disconnected');
                }
                return senderEndpoint.syncEmitEvent(channelName, eventName, arg);
            },
            disconnect: function () { return channel.disconnectSender(senderEndpoint); },
        };
    };
    EventHost.prototype.getEventReceiver = function (channelDef) {
        var subscriptionsToDispose = new Array();
        var channelName = channelDef.name;
        var channel = this.getOrCreateChannel(channelName);
        var receiverEndpoint = channel.getReceiver();
        return {
            disconnect: function () {
                subscriptionsToDispose.forEach(function (x) { return x(); });
            },
            on: function (eventName, handler) {
                var unsubscribe = receiverEndpoint.onEvent(channelDef.name, eventName, handler);
                subscriptionsToDispose.push(unsubscribe);
                return unsubscribe;
            },
        };
    };
    EventHost.prototype.getOrCreateChannel = function (channelName) {
        var channel = this.channels.get(channelName);
        if (!channel) {
            channel = this.eventChannelFactory();
            this.channels.set(channelName, channel);
        }
        return channel;
    };
    return EventHost;
}());
exports.EventHost = EventHost;
