"use strict";
/*!
 * © 2020 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var HandlerPriority;
(function (HandlerPriority) {
    HandlerPriority[HandlerPriority["High"] = 100] = "High";
    HandlerPriority[HandlerPriority["Medium"] = 500] = "Medium";
    HandlerPriority[HandlerPriority["Low"] = 1000] = "Low";
})(HandlerPriority = exports.HandlerPriority || (exports.HandlerPriority = {}));
