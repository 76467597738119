"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutReader = exports.NoopExtensionConfig = void 0;
const inversify_1 = require("inversify");
require("reflect-metadata");
const logger_service_1 = require("../logger/logger.service");
const layout_config_1 = require("./layout-config");
class NoopExtensionConfig {
    constructor(uid) {
        this.uid = uid;
        this.name = '';
        this.regions = [];
        this.renderer = {};
        this.parameters = {};
    }
}
exports.NoopExtensionConfig = NoopExtensionConfig;
const noLayoutFoundConfig = {
    rootExtension: {
        regions: [],
        renderer: {},
        uid: 'no-config-found',
        name: 'root',
        parameters: {},
    },
};
// tslint:disable-next-line:max-classes-per-file
let LayoutReader = class LayoutReader {
    constructor(logger) {
        this.logger = logger;
        this.extensionMap = new Map();
        this.metadataExtensionMap = new Map();
        this.windowEventExtensionMap = new Map();
        this.config = this.findConfig();
        this.logger.group('Read layout config');
        this.trackAllExtensionsByUid([this.config.rootExtension]);
        this.logger.groupEnd();
    }
    findConfig() {
        try {
            const jsonTag = document.getElementById('sitecore-layout-config');
            let layoutConfig;
            if (jsonTag) {
                layoutConfig = JSON.parse(jsonTag.textContent || '');
                this.logger.info('Layout config parsed successfully');
            }
            else {
                layoutConfig = noLayoutFoundConfig;
                this.logger.error(new Error('Could not find <script id="sitecore-layout-config">'));
            }
            return layoutConfig;
        }
        catch (e) {
            this.logger.error(new Error('Could not find <script id="sitecore-layout-config">'));
            return noLayoutFoundConfig;
        }
    }
    getExtensionByRendererName(name) {
        return this.windowEventExtensionMap.get(name);
    }
    getExtensionByUid(uid) {
        return this.extensionMap.get(uid) || this.metadataExtensionMap.get(uid);
    }
    trackAllExtensionsByUid(extensions) {
        let nextLevel = [];
        extensions.forEach((ext) => {
            if (!ext.uid) {
                this.logger.error(new Error('Extension in config is missing required "uid" property'), ext);
                return;
            }
            this.logger.info('Tracking extension', ext.uid);
            if (!layout_config_1.isExtensionConfig(ext)) {
                this.metadataExtensionMap.set(ext.uid, ext);
            }
            else {
                this.extensionMap.set(ext.uid, ext);
                if (ext.renderer.client && layout_config_1.isWindowEventRenderer(ext.renderer.client)) {
                    const windowEventRenderer = ext.renderer.client;
                    this.windowEventExtensionMap.set(windowEventRenderer.name, ext);
                }
                if (!ext.regions) {
                    this.logger.warn('Extension config is missing "regions" property, patching the config');
                    ext.regions = [];
                }
                ext.regions.forEach((region) => {
                    nextLevel = nextLevel.concat(region.extensions);
                });
            }
        });
        if (nextLevel.length > 0) {
            this.trackAllExtensionsByUid(nextLevel);
        }
    }
};
LayoutReader = __decorate([
    inversify_1.injectable(),
    __param(0, inversify_1.inject('LoggerService')),
    __metadata("design:paramtypes", [logger_service_1.LoggerService])
], LayoutReader);
exports.LayoutReader = LayoutReader;
