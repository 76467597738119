"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtensionRendererService = void 0;
const inversify_1 = require("inversify");
const lodash_1 = require("lodash");
const layout_config_1 = require("../config/layout-config");
const layout_reader_1 = require("../config/layout-reader");
const extension_service_1 = require("../extension/extension-service");
const logger_service_1 = require("../logger/logger.service");
let ExtensionRendererService = class ExtensionRendererService {
    constructor(extensionService, layoutReader, logger) {
        this.extensionService = extensionService;
        this.layoutReader = layoutReader;
        this.logger = logger;
    }
    getRenderingByUid(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            const config = this.layoutReader.getExtensionByUid(uid);
            if (!config) {
                this.logger.error(new Error(`No extension is registered for {ExtensionUid}`), uid);
                return undefined;
            }
            if (!layout_config_1.isExtensionConfig(config)) {
                this.logger.error(new Error(`Trying to get a renderer for {ExtensionName} metadata extension. Metadata extension doesn't have renderers`), config.name);
                return undefined;
            }
            try {
                return yield this.extensionService.getExtensionByConfig(config);
            }
            catch (reason) {
                this.logger.error(new Error(`Failed to get extension renderer for {ExtensionName}. ${reason}`), config.name);
            }
            return undefined;
        });
    }
    getContextByUid(extensionUid) {
        const config = this.layoutReader.getExtensionByUid(extensionUid);
        if (!config) {
            this.logger.error(new Error(`No extension is registered for {ExtensionUid}`), extensionUid);
            return undefined;
        }
        if (!layout_config_1.isExtensionConfig(config)) {
            this.logger.error(new Error(`Trying to build a rendering context for {ExtensionName} metadata extension.`), config.name);
            return undefined;
        }
        return this.mapExtensionToContext(config);
    }
    getRootUid() {
        return this.layoutReader.config.rootExtension.uid;
    }
    getRootContext() {
        return this.mapExtensionToContext(this.layoutReader.config.rootExtension);
    }
    getExtensionNameByUid(uid) {
        const config = this.layoutReader.getExtensionByUid(uid);
        if (config) {
            return config.name;
        }
        return `{not existing extension ${uid}}`;
    }
    mapExtensionToContext(extension) {
        return {
            regions: extension.regions.map(({ name: rName, extensions, inactiveExtensions }) => ({
                name: rName,
                extensions: extensions.map(this.mapChildExtensionToContext),
                inactiveExtensions: (inactiveExtensions || []).map(this.mapChildExtensionToContext),
            })),
            basePath: extension.basePath || '',
            subPath: extension.subPath || '',
            parameters: lodash_1.cloneDeep(extension.parameters) || {},
        };
    }
    mapChildExtensionToContext(extension) {
        return {
            uid: extension.uid,
            name: extension.name,
            parameters: lodash_1.cloneDeep(extension.parameters) || {},
            basePath: extension.basePath || '',
            subPath: extension.subPath || '',
            renderable: layout_config_1.isExtensionConfig(extension),
        };
    }
};
ExtensionRendererService = __decorate([
    inversify_1.injectable(),
    __param(0, inversify_1.inject('ExtensionService')),
    __param(1, inversify_1.inject('LayoutReader')),
    __param(2, inversify_1.inject('LoggerService')),
    __metadata("design:paramtypes", [extension_service_1.ExtensionService,
        layout_reader_1.LayoutReader,
        logger_service_1.LoggerService])
], ExtensionRendererService);
exports.ExtensionRendererService = ExtensionRendererService;
