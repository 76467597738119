"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function isChannelMessage(message) {
    return !!message && 'channelName' in message && 'type' in message;
}
exports.isChannelMessage = isChannelMessage;
function isChannelEmitMessage(message) {
    return message.type === 'EMIT';
}
exports.isChannelEmitMessage = isChannelEmitMessage;
function isChannelSyncEmitMessage(message) {
    return message.type === 'SYNC_EMIT';
}
exports.isChannelSyncEmitMessage = isChannelSyncEmitMessage;
function isChannelRpcCallMessage(message) {
    return message.type === 'RPC_CALL';
}
exports.isChannelRpcCallMessage = isChannelRpcCallMessage;
function isChannelPendingResultMessage(message) {
    return message.type === 'PENDING_RESULT';
}
exports.isChannelPendingResultMessage = isChannelPendingResultMessage;
