"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SitecoreExtension = exports.sitecoreExtensionFactory = void 0;
const mount_extension_1 = require("./mount-extension");
exports.sitecoreExtensionFactory = (extensionRenderer, logger) => {
    return class SitecoreExtensionImpl extends SitecoreExtension {
        constructor() {
            super(extensionRenderer, logger);
        }
    };
};
// tslint:disable-next-line: max-classes-per-file
class SitecoreExtension extends HTMLElement {
    constructor(extensionRenderer, logger) {
        super();
        this.extensionRenderer = extensionRenderer;
        this.logger = logger;
        this.isMounted = '';
        this.isConnectedToDom = false;
    }
    static get observedAttributes() {
        return [mount_extension_1.UID_ATTR];
    }
    set scUid(value) {
        // Use == to check for both null and undefined
        if (value == null) {
            this.removeAttribute(mount_extension_1.UID_ATTR);
        }
        else {
            this.setAttribute(mount_extension_1.UID_ATTR, value);
        }
    }
    get scUid() {
        return this.getAttribute(mount_extension_1.UID_ATTR);
    }
    set input(value) {
        if (this.inputParam === value) {
            return;
        }
        const oldInput = this.inputParam;
        this.inputParam = value;
        const uid = this.scUid;
        if (this.isMounted && uid) {
            this.extensionRenderer.dispatchInputChange({ uid, oldInput, newInput: this.inputParam });
        }
    }
    get input() {
        return this.inputParam;
    }
    connectedCallback() {
        this.isConnectedToDom = true;
        this.checkReadyForMount();
    }
    attributeChangedCallback() {
        this.checkReadyForMount();
    }
    disconnectedCallback() {
        this.isConnectedToDom = false;
        if (!this.isMounted) {
            return;
        }
        const unmountData = { uid: this.isMounted, rootElement: this };
        this.extensionRenderer.queueUnmountRequest(unmountData);
    }
    checkReadyForMount() {
        const uid = this.scUid;
        if (!this.isMounted && this.isConnectedToDom && uid) {
            this.logger.group(uid);
            this.logger.info(`Request to mount: {ExtensionUid}`, uid);
            this.isMounted = uid; // store UID instead of boolean, to ensure unmounting of the same UID
            const mountData = { uid, rootElement: this, input: this.inputParam };
            this.extensionRenderer.queueMountRequest(mountData);
            this.logger.groupEnd();
        }
    }
}
exports.SitecoreExtension = SitecoreExtension;
