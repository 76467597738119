"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var tiny_emitter_1 = require("tiny-emitter");
var native_messaging_port_1 = require("../connection/native-messaging-port");
var RpcChannel = /** @class */ (function () {
    function RpcChannel(rpcFactory) {
        this.rpcFactory = rpcFactory;
        this.active = false;
        this.rpcStatusEmitter = new tiny_emitter_1.TinyEmitter();
        this.sender = rpcFactory.createSenderEndpoint();
        this.receiver = rpcFactory.createReceiverEndpoint();
    }
    Object.defineProperty(RpcChannel.prototype, "channelState", {
        get: function () {
            return this.active;
        },
        enumerable: true,
        configurable: true
    });
    RpcChannel.prototype.activate = function (messageChannel) {
        if (!this.sender) {
            this.sender = this.rpcFactory.createSenderEndpoint();
        }
        if (this.sender && this.receiver) {
            if (this.receiver.getState() === 'disconnected') {
                this.receiver.connect(new native_messaging_port_1.NativeMessagingPort(messageChannel.port2));
            }
            this.active = true;
            this.sender.connect(new native_messaging_port_1.NativeMessagingPort(messageChannel.port1));
            this.rpcStatusEmitter.emit(RpcChannel.statusChangedEvent, this.active);
        }
    };
    RpcChannel.prototype.deactivate = function () {
        if (this.sender && this.receiver && this.active) {
            this.active = false;
            this.receiver.disconnect();
            this.sender.disconnect();
            delete this.sender;
            this.rpcStatusEmitter.emit(RpcChannel.statusChangedEvent, this.active);
        }
    };
    RpcChannel.prototype.onStateChanged = function (handler) {
        this.rpcStatusEmitter.once(RpcChannel.statusChangedEvent, handler);
    };
    RpcChannel.statusChangedEvent = 'RPC_CHANNEL_STATUS_CHANGED';
    return RpcChannel;
}());
exports.RpcChannel = RpcChannel;
