"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFlattenedListBy = exports.getDeepElementCloneWithEmptySelector = void 0;
function getDeepElementCloneWithEmptySelector(element, emptySelector) {
    const clone = element.cloneNode(true);
    const clonesToRemove = clone.querySelectorAll(emptySelector);
    clonesToRemove.forEach((elementToBeRemoved) => {
        elementToBeRemoved.textContent = '';
    });
    return clone;
}
exports.getDeepElementCloneWithEmptySelector = getDeepElementCloneWithEmptySelector;
/**
 * Finds all elements by selector, and return a flat list of them (all nested tags will be on one level).
 * All content of each sub element with same selector is removed.
 * @param rootElement Where to start searching from in the DOM
 * @param selector The selector to search by, for example tag name
 */
function getFlattenedListBy(rootElement, selector) {
    const allTagsToBeCached = rootElement.querySelectorAll(selector);
    const renderedTags = [];
    allTagsToBeCached.forEach((element) => {
        const cloneMinusCachedSubTags = getDeepElementCloneWithEmptySelector(element, selector);
        renderedTags.push(cloneMinusCachedSubTags);
    });
    return renderedTags;
}
exports.getFlattenedListBy = getFlattenedListBy;
