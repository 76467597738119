"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var native_messaging_port_1 = require("../connection/native-messaging-port");
var EventChannel = /** @class */ (function () {
    function EventChannel(senderFactory, receiverFactory) {
        this.senderFactory = senderFactory;
        this.receiverFactory = receiverFactory;
    }
    Object.defineProperty(EventChannel.prototype, "senderConnected", {
        get: function () {
            return !!this.senderEndpoint && this.senderEndpoint.getState() === 'connected';
        },
        enumerable: true,
        configurable: true
    });
    EventChannel.prototype.connectSender = function () {
        this.senderEndpoint = this.senderFactory();
        if (!this.receiverEndpoint) {
            this.receiverEndpoint = this.receiverFactory();
        }
        this.connect();
        return this.senderEndpoint;
    };
    EventChannel.prototype.disconnectSender = function (senderEndpoint) {
        senderEndpoint.disconnect();
        if (this.senderEndpoint === senderEndpoint) {
            delete this.senderEndpoint;
            if (this.receiverEndpoint) {
                this.receiverEndpoint.disconnect();
            }
        }
    };
    EventChannel.prototype.getReceiver = function () {
        if (!this.receiverEndpoint) {
            this.receiverEndpoint = this.receiverFactory();
        }
        this.connect();
        return this.receiverEndpoint;
    };
    EventChannel.prototype.connect = function () {
        if (this.senderEndpoint && this.receiverEndpoint) {
            if (this.senderEndpoint.getState() === 'connected' && this.receiverEndpoint.getState() === 'connected') {
                return;
            }
            var _a = new MessageChannel(), port1 = _a.port1, port2 = _a.port2;
            this.senderEndpoint.connect(new native_messaging_port_1.NativeMessagingPort(port1));
            this.receiverEndpoint.connect(new native_messaging_port_1.NativeMessagingPort(port2));
        }
    };
    return EventChannel;
}());
exports.EventChannel = EventChannel;
