"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
function __export(m) {
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
var event_host_1 = require("./event/event-host");
exports.EventHost = event_host_1.EventHost;
__export(require("./messaging-setup"));
var rpc_channel_factory_1 = require("./rpc/rpc-channel-factory");
exports.RpcChannelFactory = rpc_channel_factory_1.RpcChannelFactory;
var rpc_host_1 = require("./rpc/rpc-host");
exports.RpcHost = rpc_host_1.RpcHost;
