"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const command_manager_1 = require("./command-manager/command-manager");
const di_container_1 = require("./di-container");
const extension_renderer_1 = require("./extension-renderer/extension-renderer");
const sitecore_extension_1 = require("./extension/sitecore-extension");
const sitecore_region_1 = require("./region/sitecore-region");
const logger = di_container_1.container.get('LoggerService');
const extensionService = di_container_1.container.get('ExtensionRendererService');
const extensionRenderer = di_container_1.container.resolve(extension_renderer_1.ExtensionRenderer);
extensionRenderer.onInit();
const commandManager = di_container_1.container.resolve(command_manager_1.CommandManager);
window.FED_UI = {
    isReady: true,
    getCommandManager: () => commandManager,
};
logger.info('customElements.define: <sitecore-extension>');
customElements.define('sitecore-extension', sitecore_extension_1.sitecoreExtensionFactory(extensionRenderer, logger));
logger.info('customElements.define: <sitecore-region>');
customElements.define('sitecore-region', sitecore_region_1.sitecoreRegionFactory(extensionService, logger));
