"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtensionMessagingService = void 0;
const inversify_1 = require("inversify");
let ExtensionMessagingService = class ExtensionMessagingService {
    constructor(globalMessaging) {
        this.globalMessaging = globalMessaging;
        this.activeMountConnections = new Map();
    }
    disconnectMountConnections(uid) {
        const connections = this.activeMountConnections.get(uid);
        if (connections) {
            connections.forEach((disconect) => disconect());
            this.activeMountConnections.delete(uid);
        }
    }
    createMountMessaging(uid) {
        return {
            createEventEmitter: (contract) => {
                const emitter = this.globalMessaging.createEventEmitter(contract);
                this.registerMountConnection(uid, emitter.disconnect);
                return emitter;
            },
            createRpc: (contract, impl) => {
                const provider = this.globalMessaging.createRpc(contract, impl);
                this.registerMountConnection(uid, provider.disconnect);
                return provider;
            },
            getEventReceiver: (contract) => {
                const reciever = this.globalMessaging.getEventReceiver(contract);
                this.registerMountConnection(uid, reciever.disconnect);
                return reciever;
            },
            getRpc: (contract) => {
                return this.globalMessaging.getRpc(contract);
            },
        };
    }
    createBootstrapMessaging() {
        return {
            createEventEmitter: (contract) => {
                return this.globalMessaging.createEventEmitter(contract);
            },
            createRpc: (contract, impl) => {
                return this.globalMessaging.createRpc(contract, impl);
            },
            getEventReceiver: (contract) => {
                return this.globalMessaging.getEventReceiver(contract);
            },
            getRpc: (contract) => {
                return this.globalMessaging.getRpc(contract);
            },
        };
    }
    registerMountConnection(uid, disconnect) {
        this.activeMountConnections.set(uid, [...(this.activeMountConnections.get(uid) || []), disconnect]);
    }
};
ExtensionMessagingService = __decorate([
    inversify_1.injectable(),
    __param(0, inversify_1.inject('GlobalMessaging')),
    __metadata("design:paramtypes", [Object])
], ExtensionMessagingService);
exports.ExtensionMessagingService = ExtensionMessagingService;
