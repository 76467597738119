"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtensionService = exports.isExtensionObject = exports.isExtensionConstructor = void 0;
const inversify_1 = require("inversify");
const logger_service_1 = require("../logger/logger.service");
const es2015_extension_handler_1 = require("./handlers/es2015-extension.handler");
const unknown_extension_handler_1 = require("./handlers/unknown-extension.handler");
const window_event_extension_handler_1 = require("./handlers/window-event-extension.handler");
function isExtensionConstructor(extension) {
    return !!extension && typeof (extension) === 'function';
}
exports.isExtensionConstructor = isExtensionConstructor;
function isExtensionObject(extension) {
    return !!extension && typeof (extension) === 'object';
}
exports.isExtensionObject = isExtensionObject;
let ExtensionService = class ExtensionService {
    constructor(es2015ExtensionHandler, windowEventExtensionHandler, unknownExtensionHandler, logger) {
        this.es2015ExtensionHandler = es2015ExtensionHandler;
        this.windowEventExtensionHandler = windowEventExtensionHandler;
        this.unknownExtensionHandler = unknownExtensionHandler;
        this.logger = logger;
        this.chainOfExtensionHandlers = this.es2015ExtensionHandler;
        this.chainOfExtensionHandlers.setSuccessor(this.windowEventExtensionHandler).setSuccessor(this.unknownExtensionHandler);
    }
    getExtensionByConfig(config) {
        return __awaiter(this, void 0, void 0, function* () {
            const clientRenderer = config.renderer.client;
            if (clientRenderer) {
                const extension = yield this.chainOfExtensionHandlers.handleRenderer(clientRenderer);
                if (isExtensionConstructor(extension)) {
                    return new extension();
                }
                if (isExtensionObject(extension)) {
                    return extension;
                }
                return undefined;
            }
            else {
                this.logger.warn('Ignoring request to get extension for {ExtensionUid} as it doesnt have a client renderer defined.', config.uid);
                return undefined;
            }
        });
    }
};
ExtensionService = __decorate([
    inversify_1.injectable(),
    __param(0, inversify_1.inject('Es2015ExtensionHandler')),
    __param(1, inversify_1.inject('WindowEventExtensionHandler')),
    __param(2, inversify_1.inject('UnknownExtensionHandler')),
    __param(3, inversify_1.inject('LoggerService')),
    __metadata("design:paramtypes", [es2015_extension_handler_1.Es2015ExtensionHandler,
        window_event_extension_handler_1.WindowEventExtensionHandler,
        unknown_extension_handler_1.UnknownExtensionHandler,
        logger_service_1.LoggerService])
], ExtensionService);
exports.ExtensionService = ExtensionService;
