"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoggerService = void 0;
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
const inversify_1 = require("inversify");
const endpoint_config_1 = require("./../config/endpoint-config");
let LoggerService = class LoggerService {
    constructor(queryString) {
        this.isDebugMode = false;
        this.isDebugMode = queryString.includes('sc_debug=1');
    }
    logToServer(message, parameters) {
        const data = {
            type: 'error',
            message,
            parameters,
        };
        fetch(endpoint_config_1.EndPointConfig.LoggerEndPoint, {
            method: 'POST',
            body: JSON.stringify(data),
        });
    }
    formatMessage(template, params) {
        // find all placeholders framed by curly braces, e.g. {placeholder} in "This is {placeholder} in the message."
        const matches = template.match(/({[^{}]+})/gm);
        if (!matches) {
            return { message: template, parameters: {}, remainingParams: params };
        }
        let idx = 0;
        let message = template;
        const parameters = {};
        for (const match of matches) {
            if (params[idx]) {
                const key = match.slice(1, -1);
                parameters[key] = params[idx];
                const replaceValue = typeof params[idx] === 'object' ? JSON.stringify(params[idx]) : params[idx].toString();
                message = message.replace(match, replaceValue);
            }
            idx++;
        }
        return { message, parameters, remainingParams: params.slice(idx) };
    }
    group(groupTitle, ...optionalParams) {
        if (this.isDebugMode) {
            console.groupCollapsed(groupTitle, ...optionalParams);
        }
    }
    groupEnd() {
        if (this.isDebugMode) {
            console.groupEnd();
        }
    }
    warn(message, ...optionalParams) {
        if (this.isDebugMode) {
            const logData = this.formatMessage(message, optionalParams);
            console.warn('%c SC_DEBUG:', 'color: blue', logData.message, ...logData.remainingParams);
        }
    }
    info(message, ...optionalParams) {
        if (this.isDebugMode) {
            const logData = this.formatMessage(message, optionalParams);
            // tslint:disable-next-line: no-console
            console.info('%c SC_DEBUG:', 'color: blue', logData.message, ...logData.remainingParams);
        }
    }
    error(error, ...optionalParams) {
        const errorMessage = error.stack ? error.stack : error.toString();
        const logData = this.formatMessage(errorMessage, optionalParams);
        if (this.isDebugMode) {
            console.error('%c SC_DEBUG:', 'color: blue', logData.message, ...logData.remainingParams);
        }
        else {
            // Do show errors in production
            console.error(logData.message, ...logData.remainingParams);
        }
        this.logToServer(logData.message, logData.parameters);
    }
};
LoggerService = __decorate([
    inversify_1.injectable(),
    __metadata("design:paramtypes", [String])
], LoggerService);
exports.LoggerService = LoggerService;
