"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../utils");
var NativeMessagingPort = /** @class */ (function () {
    function NativeMessagingPort(messagePort) {
        var _this = this;
        this.messagePort = messagePort;
        this.handlers = new Array();
        messagePort.onmessage = function (ev) { return _this.handlers.forEach(function (h) { return h(ev.data); }); };
    }
    NativeMessagingPort.prototype.onMessage = function (handler) {
        var _this = this;
        this.handlers.push(handler);
        return function () { return utils_1.removeFromArray(handler, _this.handlers); };
    };
    NativeMessagingPort.prototype.postMessage = function (msg) {
        this.messagePort.postMessage(msg);
    };
    return NativeMessagingPort;
}());
exports.NativeMessagingPort = NativeMessagingPort;
