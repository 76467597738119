"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SitecoreRegion = exports.sitecoreRegionFactory = exports.NAME_ATTR = void 0;
const page_composer_sdk_1 = require("@sitecore/page-composer-sdk");
const mount_extension_1 = require("../extension/mount-extension");
exports.NAME_ATTR = 'name';
exports.sitecoreRegionFactory = (extensionService, logger) => {
    return class SitecoreRegionImpl extends SitecoreRegion {
        constructor() {
            super(extensionService, logger);
        }
    };
};
// tslint:disable-next-line: max-classes-per-file
class SitecoreRegion extends HTMLElement {
    constructor(extensionService, logger) {
        super();
        this.extensionService = extensionService;
        this.logger = logger;
        this.isConnectedToDom = false;
        this.filterFn = () => true;
        this.buildExtensions = (context) => {
            if (!context || !context.regions || !this.isConnectedToDom || !this.scName) {
                return;
            }
            const model = new page_composer_sdk_1.ContextModel(context);
            const hasRegion = model.hasRegion(this.scName);
            if (!hasRegion) {
                this.logger.warn(`No regions found in configuration with name  {RegionName}`, this.scName);
                return;
            }
            const extensions = model.getExtensionsByRegion(this.scName).filter((e) => e.renderable);
            const activeExtensions = extensions.filter((ext) => this.filterFn(ext, this.inputParam));
            // remove inactive extensions
            this.querySelectorAll(':scope > sitecore-extension').forEach((element) => {
                const extensionElement = element;
                if (activeExtensions.findIndex((ext) => ext.uid === extensionElement.getAttribute('sc-uid')) === -1) {
                    this.removeChild(extensionElement);
                }
            });
            let relativeElement = this;
            let position = 'afterbegin';
            // insert active extensions:
            activeExtensions.forEach((activeExtension) => {
                // Render extension if it's not rendered from the previous activation
                let extElement = this.querySelector(`sitecore-extension[sc-uid='${activeExtension.uid}']`);
                if (extElement === null) {
                    extElement = document.createElement('sitecore-extension');
                    extElement.setAttribute('sc-uid', activeExtension.uid);
                    extElement.input = this.input;
                    relativeElement.insertAdjacentElement(position, extElement);
                }
                else {
                    extElement.input = this.input;
                }
                // Move to next insert position to preserve ordering
                relativeElement = extElement;
                position = 'afterend';
            });
        };
    }
    static get observedAttributes() {
        return [exports.NAME_ATTR];
    }
    set scName(value) {
        // Use == to check for both null and undefined
        if (value == null) {
            this.removeAttribute(exports.NAME_ATTR);
        }
        else {
            this.setAttribute(exports.NAME_ATTR, value);
        }
    }
    get scName() {
        return this.getAttribute(exports.NAME_ATTR);
    }
    set filter(value) {
        this.filterFn = value;
        this.renderRegion();
    }
    get filter() {
        return this.filterFn;
    }
    set input(value) {
        this.inputParam = value;
        this.renderRegion();
    }
    get input() {
        return this.inputParam;
    }
    connectedCallback() {
        this.isConnectedToDom = true;
        this.renderRegion();
    }
    attributeChangedCallback(name) {
        if (name === exports.NAME_ATTR) {
            this.renderRegion();
        }
    }
    disconnectedCallback() {
        this.isConnectedToDom = false;
    }
    renderRegion() {
        const name = this.scName;
        if (this.isConnectedToDom && name) {
            let context;
            this.logger.info(`Request to render region: {RegionName}`, name);
            const parentExt = this.closest('sitecore-extension');
            if (!parentExt) {
                this.logger.info(`No parent extension tag was found for the region {RegionName}, root extension is used instead`, name);
                context = this.extensionService.getRootContext();
            }
            else {
                const uid = parentExt.getAttribute(mount_extension_1.UID_ATTR);
                if (!uid) {
                    this.logger.error(new Error(`Parent extension tag sc-uid attribute was not found for the region {RegionName}`), name);
                    return;
                }
                context = this.extensionService.getContextByUid(uid);
            }
            this.buildExtensions(context);
        }
    }
}
exports.SitecoreRegion = SitecoreRegion;
