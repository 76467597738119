"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandlerPriority = exports.CommandManager = void 0;
/*!
 * © 2020 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
const connection_endpoint_1 = require("@sitecore/federatedui-messaging/dist/connection/connection-endpoint");
const native_messaging_port_1 = require("@sitecore/federatedui-messaging/dist/connection/native-messaging-port");
const inversify_1 = require("inversify");
require("reflect-metadata");
const logger_service_1 = require("../logger/logger.service");
const command_manager_client_1 = require("./command-manager-client");
const command_manager_host_1 = require("./command-manager-host");
let CommandManager = class CommandManager {
    constructor(logger) {
        this.logger = logger;
        const mc = new MessageChannel();
        const hostPort = new native_messaging_port_1.NativeMessagingPort(mc.port1);
        this.commandManagerHost = new command_manager_host_1.CommandManagerHost(hostPort, logger, new connection_endpoint_1.ConnectionEndpoint());
        const clientPort = new native_messaging_port_1.NativeMessagingPort(mc.port2);
        this.commandManagerClient = new command_manager_client_1.CommandManagerClient(clientPort, logger, new connection_endpoint_1.ConnectionEndpoint());
    }
    register(command, handler, priority = HandlerPriority.Medium) {
        return __awaiter(this, void 0, void 0, function* () {
            this.logger.info(`Start command ${command} registering`);
            const regInfo = yield this.commandManagerClient.register(command, handler, priority);
            this.logger.info(`Command ${command} registered successfully`);
            return {
                unregister: () => {
                    this.logger.info(`Start command ${command} unregistering`);
                    this.unregister(regInfo);
                },
            };
        });
    }
    invoke(command, context) {
        this.logger.info(`Start command ${command} invoking`);
        return this.commandManagerHost.invoke(command, context);
    }
    unregister(info) {
        this.commandManagerClient.unregister(info);
        this.commandManagerHost.unregister(info);
    }
};
CommandManager = __decorate([
    inversify_1.injectable(),
    __param(0, inversify_1.inject('LoggerService')),
    __metadata("design:paramtypes", [logger_service_1.LoggerService])
], CommandManager);
exports.CommandManager = CommandManager;
var HandlerPriority;
(function (HandlerPriority) {
    HandlerPriority[HandlerPriority["High"] = 100] = "High";
    HandlerPriority[HandlerPriority["Medium"] = 500] = "Medium";
    HandlerPriority[HandlerPriority["Low"] = 1000] = "Low";
})(HandlerPriority = exports.HandlerPriority || (exports.HandlerPriority = {}));
