"use strict";
/*!
 * © 2020 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandManagerHost = void 0;
class CommandManagerHost {
    constructor(messagingPort, logger, hostConnectionEndpoint) {
        this.logger = logger;
        this.hostConnectionEndpoint = hostConnectionEndpoint;
        this.commandsMap = new Map();
        this.hostConnectionEndpoint.connect(messagingPort);
        this.hostConnectionEndpoint.setRpcServicesImplementation('command-manager-host', {
            register: (commandHandler) => {
                if (commandHandler) {
                    this.logger.info(`CommandManagerHost: Register handler to ${commandHandler.command},
            id:${commandHandler.id} with prioirty ${commandHandler.priority}`);
                    this.register(commandHandler);
                }
            },
        });
    }
    invoke(command, context) {
        return __awaiter(this, void 0, void 0, function* () {
            this.logger.info(`CommandManagerHost: Start invoking command ${command} with context: `, context);
            const idsMap = this.commandsMap.get(command);
            if (!idsMap) {
                this.logger.info(`CommandManagerHost: Handlers not found for command: ${command}, resolving with context`);
                return Promise.resolve(context);
            }
            let result = context;
            for (const handlerInfo of idsMap) {
                try {
                    result = yield this.callHandler(command, handlerInfo.id, result);
                }
                catch (error) {
                    this.logger.error(new Error(`CommandManagerHost: Handler ${handlerInfo.id} rejected for command: ${command}`));
                    return Promise.reject({ message: error });
                }
            }
            this.logger.info(`CommandManagerHost: All the handlers resolved for the command ${command}, result: `, result);
            return Promise.resolve(result);
        });
    }
    register(commandHandlerInfo) {
        const { command, id, priority } = commandHandlerInfo;
        this.logger.info(`CommandManagerHost: Registering handler the command ${command}, id: ${id}, priority: ${priority} `);
        if (this.commandsMap.has(command)) {
            const handlers = this.commandsMap.get(command) || [];
            handlers.push({ id, priority });
            // sorting handlers Map according to the priority value
            handlers.sort((a, b) => a.priority - b.priority);
            this.commandsMap.set(command, handlers);
        }
        else {
            this.commandsMap.set(command, [{ id, priority }]);
        }
        this.logger.info(`CommandManagerHost: Registered handler the command ${command}, id: ${id}, priority: ${priority} `);
    }
    unregister(info) {
        const handlerIds = this.commandsMap.get(info.command);
        if (!handlerIds) {
            return;
        }
        const index = handlerIds.findIndex((h) => h.id === info.id);
        if (index > -1) {
            handlerIds.splice(index, 1);
        }
        this.logger.info(`CommandManagerHost: UnRegistered handler the command ${info.command}, id: ${info.id}`);
    }
    callHandler(command, handlerId, context) {
        return this.hostConnectionEndpoint.performRpcCall('command-manager-client', 'invoke', [{ command, id: handlerId, context }]);
    }
}
exports.CommandManagerHost = CommandManagerHost;
