"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isExtensionConfig = exports.isES2015Renderer = exports.isWindowEventRenderer = void 0;
function isWindowEventRenderer(renderer) {
    return renderer.type === 'window-event';
}
exports.isWindowEventRenderer = isWindowEventRenderer;
function isES2015Renderer(renderer) {
    return !renderer.type || renderer.type === 'es2015';
}
exports.isES2015Renderer = isES2015Renderer;
function isExtensionConfig(config) {
    return config.renderer !== undefined;
}
exports.isExtensionConfig = isExtensionConfig;
