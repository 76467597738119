"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../utils");
var message_types_1 = require("./message-types");
var ConnectionEndpoint = /** @class */ (function () {
    function ConnectionEndpoint() {
        this.state = {
            name: 'disconnected',
        };
        this.channelInfos = {};
    }
    ConnectionEndpoint.prototype.getState = function () {
        return this.state.name;
    };
    ConnectionEndpoint.prototype.connect = function (port) {
        var _this = this;
        // If connection is re-established, we drop the previous connection.
        // That might be required to kill e.g. pending RPC calls.
        if (this.state.name === 'connected') {
            this.disconnect();
        }
        var portUnsubscribe = port.onMessage(function (msg) { return _this.handleMessage(msg); });
        this.state = { name: 'connected', port: port, portUnsubscribe: portUnsubscribe };
    };
    ConnectionEndpoint.prototype.disconnect = function () {
        if (this.state.name === 'disconnected') {
            return;
        }
        this.state.portUnsubscribe();
        this.state = { name: 'disconnected' };
        // Fail all the calls/emits awaiting reply, as reply will never come.
        for (var _i = 0, _a = this.getAllChannels(); _i < _a.length; _i++) {
            var chInfo = _a[_i];
            var pendingRequests = chInfo.pendingRequests;
            pendingRequests.forEach(function (pendingReq) { return pendingReq.reject(Error('Underlying connection was unexpectedly closed.')); });
        }
    };
    ConnectionEndpoint.prototype.onEvent = function (channelName, eventName, handler) {
        var channelInfo = this.getOrCreateChannelInfo(channelName);
        var eventListeners = channelInfo.eventListeners[eventName];
        if (!eventListeners) {
            channelInfo.eventListeners[eventName] = eventListeners = [];
        }
        eventListeners.push(handler);
        // Unsubscribe handler
        var nonNullEventListeners = eventListeners;
        return function () { return utils_1.removeFromArray(handler, nonNullEventListeners); };
    };
    ConnectionEndpoint.prototype.emitEvent = function (channelName, eventName, arg) {
        var msg = {
            type: 'EMIT',
            channelName: channelName,
            eventName: eventName,
            arg: arg,
        };
        this.postMessage(msg);
    };
    ConnectionEndpoint.prototype.syncEmitEvent = function (channelName, eventName, arg) {
        var msg = {
            type: 'SYNC_EMIT',
            channelName: channelName,
            eventName: eventName,
            arg: arg,
            requestId: -1,
        };
        return this.postMessageAwaitingReply(msg);
    };
    ConnectionEndpoint.prototype.performRpcCall = function (channelName, methodName, args) {
        var msg = {
            type: 'RPC_CALL',
            channelName: channelName,
            methodName: methodName,
            args: args,
            requestId: -1,
        };
        if (this.state.name === 'disconnected') {
            throw Error("Underlying connection was unexpectedly closed.");
        }
        return this.postMessageAwaitingReply(msg);
    };
    ConnectionEndpoint.prototype.setRpcServicesImplementation = function (channelName, impl) {
        var channelInfo = this.getOrCreateChannelInfo(channelName);
        channelInfo.services = impl;
    };
    ConnectionEndpoint.prototype.removeRpcServicesImplementation = function (channelName) {
        var channelInfo = this.getOrCreateChannelInfo(channelName);
        channelInfo.services = {};
    };
    ConnectionEndpoint.prototype.handleMessage = function (msg) {
        if (!message_types_1.isChannelMessage(msg)) {
            console.warn("[Messaging] Unknown message received: " + JSON.stringify(msg));
            return;
        }
        if (message_types_1.isChannelEmitMessage(msg)) {
            this.handleIncomingEvent(msg.channelName, msg.eventName, msg.arg);
            return;
        }
        if (message_types_1.isChannelSyncEmitMessage(msg)) {
            this.handleIncomingSyncEvent(msg.channelName, msg.eventName, msg.arg, msg.requestId);
            return;
        }
        if (message_types_1.isChannelRpcCallMessage(msg)) {
            this.handleIncomingRpcCall(msg.channelName, msg.methodName, msg.args, msg.requestId);
            return;
        }
        if (message_types_1.isChannelPendingResultMessage(msg)) {
            this.handleIncomingPendingResult(msg.channelName, msg.requestId, msg.result, msg.error);
            return;
        }
        console.warn("[Messaging] Message of unknown type received. Type: '" + msg.type + "', Channel: '" + msg.channelName + "'");
    };
    ConnectionEndpoint.prototype.handleIncomingEvent = function (channelName, eventName, payload) {
        var chInfo = this.getOrCreateChannelInfo(channelName);
        var eventHandlers = chInfo.eventListeners[eventName] || [];
        eventHandlers.forEach(function (handler) { return handler(payload); });
    };
    ConnectionEndpoint.prototype.handleIncomingSyncEvent = function (channelName, eventName, payload, requestId) {
        return __awaiter(this, void 0, void 0, function () {
            var chInfo, msg, eventHandlers, pendingResults, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        chInfo = this.getOrCreateChannelInfo(channelName);
                        msg = {
                            type: 'PENDING_RESULT',
                            channelName: channelName,
                            requestId: requestId,
                        };
                        eventHandlers = chInfo.eventListeners[eventName] || [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        pendingResults = eventHandlers.map(function (handler) { return handler(payload); });
                        // Support both handlers returning promises and ones returning nothing.
                        return [4 /*yield*/, Promise.all(pendingResults)];
                    case 2:
                        // Support both handlers returning promises and ones returning nothing.
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        msg.error = "Event handler failed: " + (error_1 ? error_1.message : 'Unknown error');
                        return [3 /*break*/, 4];
                    case 4:
                        this.postMessage(msg);
                        return [2 /*return*/];
                }
            });
        });
    };
    ConnectionEndpoint.prototype.handleIncomingRpcCall = function (channelName, methodName, args, requestId) {
        return __awaiter(this, void 0, void 0, function () {
            var chInfo, servicesObj, service, msg, serviceResult, _a, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        chInfo = this.getOrCreateChannelInfo(channelName);
                        servicesObj = chInfo.services;
                        service = servicesObj[methodName];
                        msg = {
                            type: 'PENDING_RESULT',
                            channelName: channelName,
                            requestId: requestId,
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        if (!service) {
                            throw Error("RPC service is not registered. Channel: '" + channelName + "', Method: '" + methodName + "'");
                        }
                        serviceResult = service.apply(servicesObj, args);
                        // Support service returning a promise.
                        _a = msg;
                        return [4 /*yield*/, Promise.resolve(serviceResult)];
                    case 2:
                        // Support service returning a promise.
                        _a.result = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _b.sent();
                        msg.error = error_2.message || 'Unknown error';
                        return [3 /*break*/, 4];
                    case 4:
                        this.postMessage(msg);
                        return [2 /*return*/];
                }
            });
        });
    };
    ConnectionEndpoint.prototype.handleIncomingPendingResult = function (channelName, requestId, result, error) {
        var chInfo = this.getOrCreateChannelInfo(channelName);
        var pendingRpcIndex = chInfo.pendingRequests.findIndex(function (x) { return x.requestId === requestId; });
        if (pendingRpcIndex < 0) {
            console.warn("[Messaging] Cannot find pending request. Channel: '" + channelName + "', RequestId: '" + requestId + "'.");
            return;
        }
        var pendingReq = chInfo.pendingRequests.splice(pendingRpcIndex, 1)[0];
        // Handle error first, as result might be falsy, but still exist.
        if (error) {
            pendingReq.reject(Error(error));
        }
        else {
            pendingReq.resolve(result);
        }
    };
    ConnectionEndpoint.prototype.postMessage = function (msg) {
        if (this.state.name === 'connected') {
            this.state.port.postMessage(msg);
        }
    };
    ConnectionEndpoint.prototype.postMessageAwaitingReply = function (msg) {
        var chInfo = this.getOrCreateChannelInfo(msg.channelName);
        msg.requestId = chInfo.requestIdCounter++;
        var promise = new Promise(function (resolve, reject) {
            chInfo.pendingRequests.push({ requestId: msg.requestId, resolve: resolve, reject: reject });
        });
        this.postMessage(msg);
        return promise;
    };
    ConnectionEndpoint.prototype.getOrCreateChannelInfo = function (channelName) {
        var info = this.channelInfos[channelName];
        if (!info) {
            this.channelInfos[channelName] = info = {
                requestIdCounter: 0,
                services: {},
                eventListeners: {},
                pendingRequests: [],
            };
        }
        return info;
    };
    ConnectionEndpoint.prototype.getAllChannels = function () {
        var _this = this;
        return Object.keys(this.channelInfos)
            .map(function (key) { return _this.channelInfos[key]; })
            .filter(function (value) { return value !== undefined; });
    };
    return ConnectionEndpoint;
}());
exports.ConnectionEndpoint = ConnectionEndpoint;
