"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WindowEventExtensionHandler = void 0;
const page_composer_sdk_1 = require("@sitecore/page-composer-sdk");
const inversify_1 = require("inversify");
const layout_config_1 = require("../../config/layout-config");
const layout_reader_1 = require("../../config/layout-reader");
const logger_service_1 = require("../../logger/logger.service");
const extension_handler_1 = require("./extension.handler");
let WindowEventExtensionHandler = class WindowEventExtensionHandler extends extension_handler_1.ExtensionHandler {
    constructor(layoutReader, logger) {
        super();
        this.layoutReader = layoutReader;
        this.logger = logger;
        this.windowEventExtensions = new Map();
        this.registerWindowEventExtension = this.registerWindowEventExtension.bind(this);
        logger.info('Listen for registrations of extensions through window');
        window.addEventListener(page_composer_sdk_1.REGISTER_EXTENSION_BY_NAME, this.registerWindowEventExtension);
    }
    destroy() {
        window.removeEventListener(page_composer_sdk_1.REGISTER_EXTENSION_BY_NAME, this.registerWindowEventExtension);
    }
    handleRenderer(renderer) {
        if (layout_config_1.isWindowEventRenderer(renderer)) {
            return this.getWindowEventRegistryResolver(renderer.name);
        }
        else if (this.successor) {
            return this.successor.handleRenderer(renderer);
        }
        return Promise.resolve(undefined);
    }
    registerWindowEventExtension(event) {
        const registrations = Array.isArray(event.detail) ? event.detail : [event.detail];
        registrations.forEach((registration) => {
            const { name, extension } = registration;
            let deferredRegistration = this.windowEventExtensions.get(name);
            const ext = this.layoutReader.getExtensionByRendererName(name);
            if (!ext) {
                this.logger.warn(`Failed to find registration of extension {ExtensionName}`, name);
            }
            if (!deferredRegistration) {
                deferredRegistration = this.createDeferredExtension();
                this.windowEventExtensions.set(name, deferredRegistration);
            }
            this.logger.info('Registering window-event extension:', name);
            deferredRegistration.resolve(extension);
        });
    }
    getWindowEventRegistryResolver(name) {
        let deferredRegistration = this.windowEventExtensions.get(name);
        if (!deferredRegistration) {
            deferredRegistration = this.createDeferredExtension();
            this.logger.info('Waiting for extension with name {ExtensionName} to be registered', name);
            this.windowEventExtensions.set(name, deferredRegistration);
        }
        return Promise.race([deferredRegistration.promise, this.createTimeoutPromise(`Waiting for extension "${name}" timed out after 7000s`)]);
    }
    createTimeoutPromise(message, timeout = 7000) {
        return new Promise((_, reject) => setTimeout(() => reject(message), timeout));
    }
    createDeferredExtension() {
        let resolve = () => { };
        let reject = () => { };
        const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });
        return { promise, resolve, reject };
    }
};
WindowEventExtensionHandler = __decorate([
    inversify_1.injectable(),
    __param(0, inversify_1.inject('LayoutReader')), __param(1, inversify_1.inject('LoggerService')),
    __metadata("design:paramtypes", [layout_reader_1.LayoutReader, logger_service_1.LoggerService])
], WindowEventExtensionHandler);
exports.WindowEventExtensionHandler = WindowEventExtensionHandler;
