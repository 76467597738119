"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalMessagingImpl = void 0;
const inversify_1 = require("inversify");
const logger_service_1 = require("../logger/logger.service");
let GlobalMessagingImpl = class GlobalMessagingImpl {
    constructor(eventHostFactory, rpcHostFactory, logger) {
        this.logger = logger;
        this.eventHost = eventHostFactory();
        this.rpcHost = rpcHostFactory();
    }
    getEventReceiver(contract) {
        return this.eventHost.getEventReceiver(contract);
    }
    createEventEmitter(contract) {
        try {
            return this.eventHost.createEventEmitter(contract);
        }
        catch (e) {
            this.logger.error(e);
            throw e;
        }
    }
    getRpc(contract) {
        return this.rpcHost.getRpcService(contract);
    }
    createRpc(contract, impl) {
        try {
            return this.rpcHost.connectRpcService(contract, impl);
        }
        catch (e) {
            this.logger.error(e);
            throw e;
        }
    }
};
GlobalMessagingImpl = __decorate([
    inversify_1.injectable(),
    __param(0, inversify_1.inject('Factory<EventHost>')),
    __param(1, inversify_1.inject('Factory<RpcHost>')),
    __param(2, inversify_1.inject('LoggerService')),
    __metadata("design:paramtypes", [Function, Function, logger_service_1.LoggerService])
], GlobalMessagingImpl);
exports.GlobalMessagingImpl = GlobalMessagingImpl;
