"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CachedHTMLExtension = void 0;
class CachedHTMLExtension {
    constructor(serverHtml = '') {
        this.serverHtml = serverHtml;
    }
    mount({ rootElement }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (rootElement.childElementCount === 0) {
                rootElement.innerHTML = this.serverHtml;
            }
        });
    }
}
exports.CachedHTMLExtension = CachedHTMLExtension;
