"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var utils_interception_1 = require("../utils-interception");
var RpcHost = /** @class */ (function () {
    function RpcHost(_rpcChannelFactory) {
        this._rpcChannelFactory = _rpcChannelFactory;
        this._rpcChannels = new Map();
    }
    RpcHost.prototype.getRpcService = function (channelDef) {
        if (!this._rpcChannels.has(channelDef.name)) {
            var notActiveChannel = this._rpcChannelFactory.createChannel();
            this._rpcChannels.set(channelDef.name, notActiveChannel);
        }
        var channel = this._rpcChannels.get(channelDef.name);
        var receiver = channel.receiver;
        if (!channel.channelState) {
            return new Promise(function (resolve, reject) {
                channel.onStateChanged(function (isActive) {
                    if (!isActive) {
                        reject('RPC provider disconnected');
                    }
                    else {
                        resolve(utils_interception_1.makeCallInterceptor(channelDef.name, receiver));
                    }
                });
            });
        }
        var rpcServicesClient = utils_interception_1.makeCallInterceptor(channelDef.name, receiver);
        return Promise.resolve(rpcServicesClient);
    };
    RpcHost.prototype.connectRpcService = function (channelDef, implementation) {
        var currentChannel;
        var messageChannel = new MessageChannel();
        if (this._rpcChannels.has(channelDef.name)) {
            currentChannel = this._rpcChannels.get(channelDef.name);
            if (currentChannel && !currentChannel.channelState) {
                this.activateChannel(currentChannel, messageChannel, channelDef.name, implementation);
            }
            else {
                throw (new Error('Cannot create RPC. RPC with the same name is alredy exist'));
            }
        }
        else {
            currentChannel = this._rpcChannelFactory.createChannel();
            this.activateChannel(currentChannel, messageChannel, channelDef.name, implementation);
            this._rpcChannels.set(channelDef.name, currentChannel);
        }
        var sender = currentChannel.sender;
        return {
            disconnect: function () {
                if (sender && sender.getState() === 'connected') {
                    sender.removeRpcServicesImplementation(channelDef.name);
                    currentChannel.deactivate();
                }
            },
        };
    };
    RpcHost.prototype.activateChannel = function (channel, messageChannel, channelDefName, implementation) {
        try {
            channel.activate(messageChannel);
            if (channel.sender) {
                channel.sender.setRpcServicesImplementation(channelDefName, implementation);
            }
        }
        catch (error) {
            throw (new Error("Cannot connect to RPC '" + channelDefName + "'. Connection failed"));
        }
    };
    return RpcHost;
}());
exports.RpcHost = RpcHost;
