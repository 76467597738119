"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnknownExtensionHandler = void 0;
const inversify_1 = require("inversify");
const extension_handler_1 = require("./extension.handler");
let UnknownExtensionHandler = class UnknownExtensionHandler extends extension_handler_1.ExtensionHandler {
    handleRenderer(renderer) {
        return Promise.reject(`Cannot find handler for: ${renderer.type}`);
    }
};
UnknownExtensionHandler = __decorate([
    inversify_1.injectable()
], UnknownExtensionHandler);
exports.UnknownExtensionHandler = UnknownExtensionHandler;
