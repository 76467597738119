"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function makeCallInterceptor(channelName, endpoint) {
    return new Proxy({}, {
        get: function (_target, property) {
            if (property.toString() === 'then') {
                return;
            }
            return function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return endpoint.performRpcCall(channelName, property.toString(), args);
            };
        },
    });
}
exports.makeCallInterceptor = makeCallInterceptor;
