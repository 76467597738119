"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtensionMountQueue = void 0;
const inversify_1 = require("inversify");
let ExtensionMountQueue = class ExtensionMountQueue {
    constructor() {
        this.mountQueue = new Map();
    }
    add(detail) {
        const mountQueue = this.getMountQueueForExtension(detail.uid);
        if (!this.getMountExtension(mountQueue, detail.rootElement)) {
            mountQueue.push(detail);
            this.mountQueue.set(detail.uid, mountQueue);
        }
    }
    remove(detail) {
        const mountQueue = this.getMountQueueForExtension(detail.uid);
        const mountExtension = this.getMountExtension(mountQueue, detail.rootElement);
        if (mountExtension) {
            mountQueue.splice(mountQueue.indexOf(mountExtension), 1);
        }
        return mountQueue;
    }
    getFirst(uid) {
        return this.getMountQueueForExtension(uid)[0];
    }
    isFirst(detail) {
        const uidQueue = this.getMountQueueForExtension(detail.uid);
        const extension = this.getMountExtension(uidQueue, detail.rootElement);
        return extension && uidQueue.indexOf(extension) === 0;
    }
    has(detail) {
        const uidQueue = this.getMountQueueForExtension(detail.uid);
        return !!this.getMountExtension(uidQueue, detail.rootElement);
    }
    getMountQueueForExtension(uid) {
        return this.mountQueue.get(uid) || [];
    }
    getMountExtension(mountQueue, element) {
        return mountQueue.find((m) => m.rootElement === element);
    }
};
ExtensionMountQueue = __decorate([
    inversify_1.injectable()
], ExtensionMountQueue);
exports.ExtensionMountQueue = ExtensionMountQueue;
