"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Es2015ExtensionHandler = void 0;
const inversify_1 = require("inversify");
const layout_config_1 = require("../../config/layout-config");
const logger_service_1 = require("../../logger/logger.service");
const dynamic_import_1 = require("../../polyfills/dynamic-import");
const extension_handler_1 = require("./extension.handler");
let Es2015ExtensionHandler = class Es2015ExtensionHandler extends extension_handler_1.ExtensionHandler {
    constructor(logger) {
        super();
        this.logger = logger;
        this.esmLoader = dynamic_import_1.importModule;
        this.es2015Extensions = new Map();
    }
    handleRenderer(renderer) {
        if (layout_config_1.isES2015Renderer(renderer)) {
            const exportName = renderer.params ? renderer.params.export || 'default' : 'default';
            return this.getES2015Resolver(renderer.path, exportName);
        }
        else if (this.successor) {
            return this.successor.handleRenderer(renderer);
        }
        return Promise.resolve(undefined);
    }
    getES2015Resolver(path, exportName) {
        return __awaiter(this, void 0, void 0, function* () {
            let loader = this.es2015Extensions.get(path);
            if (!loader) {
                this.logger.info('Loading extensions from', path);
                loader = this.esmLoader(path);
                this.es2015Extensions.set(path, loader);
            }
            const extensionBundle = yield loader;
            this.logger.info('Registering es2015 extension: {ExportName} from {Path}', exportName, path);
            if (!extensionBundle[exportName]) {
                const newPath = path.indexOf('?') > 0 ? `${path}&${Date.now()}` : `${path}?${Date.now()}`;
                const reloader = this.esmLoader(newPath);
                const bundle = yield reloader;
                this.es2015Extensions.delete(path);
                this.es2015Extensions.set(path, reloader);
                if (!bundle[exportName]) {
                    this.logger.error(new Error(`Failed to load an extension {ExportName} from {Path}`), exportName, path);
                }
                return bundle[exportName];
            }
            return extensionBundle[exportName];
        });
    }
};
Es2015ExtensionHandler = __decorate([
    inversify_1.injectable(),
    __param(0, inversify_1.inject('LoggerService')),
    __metadata("design:paramtypes", [logger_service_1.LoggerService])
], Es2015ExtensionHandler);
exports.Es2015ExtensionHandler = Es2015ExtensionHandler;
