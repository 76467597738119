"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var connection_endpoint_1 = require("./connection/connection-endpoint");
var event_channel_1 = require("./event/event-channel");
var event_host_1 = require("./event/event-host");
var rpc_channel_factory_1 = require("./rpc/rpc-channel-factory");
var rpc_host_1 = require("./rpc/rpc-host");
function createMessagingRpcHost() {
    var rpcChannelFactory = new rpc_channel_factory_1.RpcChannelFactory();
    return new rpc_host_1.RpcHost(rpcChannelFactory);
}
exports.createMessagingRpcHost = createMessagingRpcHost;
function createMessagingEventHost() {
    return new event_host_1.EventHost(function () { return new event_channel_1.EventChannel(function () { return new connection_endpoint_1.ConnectionEndpoint(); }, function () { return new connection_endpoint_1.ConnectionEndpoint(); }); });
}
exports.createMessagingEventHost = createMessagingEventHost;
