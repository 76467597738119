"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.container = void 0;
const federatedui_messaging_1 = require("@sitecore/federatedui-messaging");
const inversify_1 = require("inversify");
const command_manager_1 = require("./command-manager/command-manager");
const layout_reader_1 = require("./config/layout-reader");
const extension_messaging_service_1 = require("./extension-renderer/extension-messaging-service");
const extension_mount_queue_1 = require("./extension-renderer/extension-mount-queue");
const extension_render_service_1 = require("./extension-renderer/extension-render.service");
const extension_renderer_1 = require("./extension-renderer/extension-renderer");
const extension_service_1 = require("./extension/extension-service");
const es2015_extension_handler_1 = require("./extension/handlers/es2015-extension.handler");
const unknown_extension_handler_1 = require("./extension/handlers/unknown-extension.handler");
const window_event_extension_handler_1 = require("./extension/handlers/window-event-extension.handler");
const logger_service_1 = require("./logger/logger.service");
const global_messaging_impl_1 = require("./messaging/global-messaging-impl");
exports.container = new inversify_1.Container();
exports.container.bind('LoggerService').toDynamicValue(() => new logger_service_1.LoggerService(window.location.search));
exports.container
    .bind('LayoutReader')
    .to(layout_reader_1.LayoutReader)
    .inSingletonScope();
exports.container.bind('ExtensionRenderer').to(extension_renderer_1.ExtensionRenderer);
exports.container.bind('ExtensionRendererService').to(extension_render_service_1.ExtensionRendererService);
exports.container.bind('IExtensionRendererService').to(extension_render_service_1.ExtensionRendererService);
exports.container.bind('ExtensionHandler').to(es2015_extension_handler_1.Es2015ExtensionHandler);
exports.container.bind('ExtensionHandler').to(window_event_extension_handler_1.WindowEventExtensionHandler);
exports.container.bind('ExtensionHandler').to(unknown_extension_handler_1.UnknownExtensionHandler);
exports.container.bind('Es2015ExtensionHandler').to(es2015_extension_handler_1.Es2015ExtensionHandler);
exports.container.bind('WindowEventExtensionHandler').to(window_event_extension_handler_1.WindowEventExtensionHandler);
exports.container.bind('UnknownExtensionHandler').to(unknown_extension_handler_1.UnknownExtensionHandler);
exports.container.bind('ExtensionService').to(extension_service_1.ExtensionService);
exports.container
    .bind('ExtensionMountQueue')
    .to(extension_mount_queue_1.ExtensionMountQueue)
    .inSingletonScope();
exports.container
    .bind('ExtensionMessagingService')
    .to(extension_messaging_service_1.ExtensionMessagingService)
    .inSingletonScope();
exports.container
    .bind('GlobalMessaging')
    .to(global_messaging_impl_1.GlobalMessagingImpl)
    .inSingletonScope();
exports.container.bind('Factory<EventHost>').toFactory(() => federatedui_messaging_1.createMessagingEventHost);
exports.container.bind('Factory<RpcHost>').toFactory(() => federatedui_messaging_1.createMessagingRpcHost);
exports.container.bind('CommandManager').to(command_manager_1.CommandManager);
