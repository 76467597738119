"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var ContextModel = /** @class */ (function () {
    function ContextModel(context) {
        this.context = context;
    }
    ContextModel.prototype.hasRegion = function (regionName) {
        return !!this.getRegionByName(regionName);
    };
    ContextModel.prototype.getExtensionsByRegion = function (regionName) {
        var region = this.getRegionByName(regionName);
        return region ? region.extensions : [];
    };
    ContextModel.prototype.getInactiveExtensionsByRegion = function (regionName) {
        var region = this.getRegionByName(regionName);
        return region ? region.inactiveExtensions : [];
    };
    ContextModel.prototype.getRegionByName = function (regionName) {
        return this.context.regions.find(function (region) { return !!regionName && region.name.toLowerCase() === regionName.toLowerCase(); });
    };
    return ContextModel;
}());
exports.ContextModel = ContextModel;
