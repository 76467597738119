"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var connection_endpoint_1 = require("../connection/connection-endpoint");
var rpc_channel_1 = require("./rpc-channel");
var RpcChannelFactory = /** @class */ (function () {
    function RpcChannelFactory() {
    }
    RpcChannelFactory.prototype.createChannel = function () {
        var channel = new rpc_channel_1.RpcChannel(this);
        channel.sender = this.createSenderEndpoint();
        channel.receiver = this.createReceiverEndpoint();
        return channel;
    };
    RpcChannelFactory.prototype.createSenderEndpoint = function () {
        return new connection_endpoint_1.ConnectionEndpoint();
    };
    RpcChannelFactory.prototype.createReceiverEndpoint = function () {
        return new connection_endpoint_1.ConnectionEndpoint();
    };
    return RpcChannelFactory;
}());
exports.RpcChannelFactory = RpcChannelFactory;
