"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var extension_1 = require("./extension");
exports.windowEventExtensionRegistry = {
    define: function (definition) {
        var _a;
        if ((_a = window.FED_UI) === null || _a === void 0 ? void 0 : _a.isReady) {
            window.dispatchEvent(new CustomEvent(extension_1.REGISTER_EXTENSION_BY_NAME, {
                detail: definition,
            }));
        }
        else {
            window.addEventListener('DOMContentLoaded', function () {
                return window.dispatchEvent(new CustomEvent(extension_1.REGISTER_EXTENSION_BY_NAME, {
                    detail: definition,
                }));
            });
        }
    },
};
