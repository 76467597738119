"use strict";
/*!
 * © 2019 Sitecore Corporation A/S. All rights reserved. Sitecore® is a registered trademark of Sitecore Corporation A/S.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtensionRenderer = void 0;
const inversify_1 = require("inversify");
const mount_extension_1 = require("../extension/mount-extension");
const logger_service_1 = require("../logger/logger.service");
const cached_html_extension_1 = require("../ssr-html-cache/cached-html-extension");
const dom_1 = require("../utils/dom");
const extension_messaging_service_1 = require("./extension-messaging-service");
const extension_mount_queue_1 = require("./extension-mount-queue");
/**
 * Given a uid and rootElement, Page Composer mounts and unmounts extensions.
 *
 * @example
 * window.dispatchEvent(
 *  new CustomEvent('sc-composer:mount', { detail: { uid: 'my-uid', rootElement: this } })
 * );
 */
let ExtensionRenderer = class ExtensionRenderer {
    constructor(extensionRendererService, extensionMessagingService, extensionMountQueue, logger) {
        this.extensionRendererService = extensionRendererService;
        this.extensionMessagingService = extensionMessagingService;
        this.extensionMountQueue = extensionMountQueue;
        this.logger = logger;
        this.mountedExtensions = new Map();
        this.activeExtensions = new Map();
        this.bootstrappedExtensions = new Set();
        this.ssrCache = new Map();
    }
    onInit() {
        // Cache all server-side renderings
        this.cacheServerRenderings(document.body);
        // Queue mounting of root extension, as root extension doesn't have corresponding <sitecore-extension> tag
        const rootUid = this.extensionRendererService.getRootUid();
        this.queueMountRequest({ uid: rootUid, rootElement: document.body, input: undefined });
    }
    onDestroy() {
        this.ssrCache.clear();
        this.mountedExtensions.clear();
    }
    cacheServerRenderings(rootElement) {
        this.logger.group('Cache SSR extensions');
        const sitecoreRegionServerHtmls = dom_1.getFlattenedListBy(rootElement, 'sitecore-extension');
        this.logger.info('Found', sitecoreRegionServerHtmls.length, 'SSR extensions to cache');
        // Create extensions that re-renders server-side rendered markup - one for each extension
        sitecoreRegionServerHtmls.forEach((extensionWrapperEl) => {
            const uid = extensionWrapperEl.getAttribute(mount_extension_1.UID_ATTR);
            if (!uid) {
                this.logger.warn('Cannot cache sitecore-extension SSR due to missing attribute sc-uid', extensionWrapperEl);
                return;
            }
            this.ssrCache.set(uid, new cached_html_extension_1.CachedHTMLExtension(extensionWrapperEl.innerHTML));
            this.logger.info('Cached', uid);
        });
        this.logger.groupEnd();
    }
    queueMountRequest(detail) {
        return __awaiter(this, void 0, void 0, function* () {
            this.extensionMountQueue.add(detail);
            // process with mounting only if it's the first element in queue
            const needsToWaitInQueue = !this.extensionMountQueue.isFirst(detail);
            if (needsToWaitInQueue) {
                return;
            }
            // Extension is first in the queue - process immidiately
            const mountPromise = this.mountSsrThenFetchAndMountExtension(detail);
            this.mountedExtensions.set(detail.uid, mountPromise);
            yield mountPromise;
        });
    }
    queueUnmountRequest(detail) {
        return __awaiter(this, void 0, void 0, function* () {
            const isRequestQueued = this.extensionMountQueue.has(detail);
            if (!isRequestQueued) {
                this.logger.warn('Requested to unmount {ExtensionUid}, but extension is not mounted.', detail.uid);
                return;
            }
            // extension was never called to mount
            const requestAlreadyMounting = this.mountedExtensions.get(detail.uid);
            if (!requestAlreadyMounting) {
                this.extensionMountQueue.remove(detail);
                return;
            }
            yield requestAlreadyMounting;
            try {
                yield this.fetchAndUnmountExtension(detail);
            }
            finally {
                this.extensionMountQueue.remove(detail);
            }
            this.mountedExtensions.delete(detail.uid);
            // Finally process next extension in the queue (if any)
            const nextElement = this.extensionMountQueue.getFirst(detail.uid);
            if (nextElement) {
                // We do not await for mount of next extension to finish during unmount
                this.queueMountRequest(nextElement);
            }
        });
    }
    dispatchInputChange(change) {
        return __awaiter(this, void 0, void 0, function* () {
            const extension = this.activeExtensions.get(change.uid);
            if (extension && extension.onInputChange) {
                extension.onInputChange({ newInput: change.newInput, oldInput: change.oldInput });
            }
        });
    }
    mountSsrThenFetchAndMountExtension({ rootElement, uid, input }) {
        return __awaiter(this, void 0, void 0, function* () {
            const context = this.extensionRendererService.getContextByUid(uid);
            if (!context) {
                return;
            }
            const messaging = this.extensionMessagingService.createMountMessaging(uid);
            // If there is a cached SSR extension - mount that first, until runtime extension can take over
            const ssrExtension = this.ssrCache.get(uid);
            if (ssrExtension) {
                yield ssrExtension.mount({ rootElement, context, messaging });
            }
            const extension = yield this.extensionRendererService.getRenderingByUid(uid);
            if (extension) {
                this.activeExtensions.set(uid, extension);
                this.ssrCache.delete(uid);
                const bootstrapMessaging = this.extensionMessagingService.createBootstrapMessaging();
                const name = this.extensionRendererService.getExtensionNameByUid(uid);
                if (extension.bootstrap && !this.bootstrappedExtensions.has(extension)) {
                    this.logger.info('Bootstrapping', name);
                    this.bootstrappedExtensions.add(extension);
                    try {
                        yield extension.bootstrap({ messaging: bootstrapMessaging });
                    }
                    catch (reason) {
                        this.logger.error(new Error(`Failed to bootstrap extension {ExtensionName}, exception: ${reason}`), name);
                        this.bootstrappedExtensions.delete(extension);
                    }
                }
                this.logger.info('Mounting', uid);
                try {
                    yield extension.mount({ rootElement, context, messaging, input });
                }
                catch (reason) {
                    this.logger.error(new Error(`Failed to mount extension {ExtensionName}, exception: ${reason}`), name);
                }
            }
        });
    }
    fetchAndUnmountExtension({ rootElement, uid }) {
        return __awaiter(this, void 0, void 0, function* () {
            const context = this.extensionRendererService.getContextByUid(uid);
            if (!context) {
                return;
            }
            const extension = this.activeExtensions.get(uid);
            this.activeExtensions.delete(uid);
            if (extension && extension.unmount) {
                this.logger.info('Unmounting {ExtensionUid}', uid);
                const messaging = this.extensionMessagingService.createMountMessaging(uid);
                yield extension.unmount({ rootElement, context, messaging });
            }
            this.extensionMessagingService.disconnectMountConnections(uid);
        });
    }
};
ExtensionRenderer = __decorate([
    inversify_1.injectable(),
    __param(0, inversify_1.inject('IExtensionRendererService')),
    __param(1, inversify_1.inject('ExtensionMessagingService')),
    __param(2, inversify_1.inject('ExtensionMountQueue')),
    __param(3, inversify_1.inject('LoggerService')),
    __metadata("design:paramtypes", [Object, extension_messaging_service_1.ExtensionMessagingService,
        extension_mount_queue_1.ExtensionMountQueue,
        logger_service_1.LoggerService])
], ExtensionRenderer);
exports.ExtensionRenderer = ExtensionRenderer;
